import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
// oh, hello!
// Thanks, An!
export const query = graphql`
  query($slug:String!){
    markdownRemark(
      frontmatter:{
        slug:{ eq: $slug }
      }
    ) {
      frontmatter {
        title
        date(formatString: "YYYY MMM DD")
      }
      html
    }
  }
`;

const Post = ({ data }) => {
  return <Layout><article>
    <h1>{ data.markdownRemark.frontmatter.title }</h1>
    <div>posted on { data.markdownRemark.frontmatter.date }</div>
    <section dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}/>
    <Link to="/">&larr; back to all posts</Link>
  </article></Layout>
};

export default Post;
